








import Vue from 'vue';
export default Vue.extend({
  mounted() {
    const splited = location.href.split('?');
    const queryString = splited.pop();
    location.href = `remonade://electron-redirect.remonade.app/?${queryString}`;
  },
});
